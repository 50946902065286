


/* Main Tool Bar & Container */
.Toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2em;
    padding-top: 2px;
    background-color: #d7d0c8;
    border-top: #ffffff 1px solid;
    box-shadow: #d7d0c8 0 -1px 0;
}

.TimeContainer {
    padding-right: 2px;
}

/* Main Buttons */
.ToolBarButton {
    color: black;
    width: 175px;
    font-weight: bold;
    text-align: left;
}

.ToolBarContainer {
    background-color: #d7d0c8;
    padding-left: 5px;
    width: 175px;
    height: 29px;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
    border-top: white 2px solid;
    border-left: white 2px solid;
}

.ToolbarIcon {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-left: 1px;
}

/* Start Button CSS */
.StartButton {
    color: black;
    font-weight: bold;
    text-align: left;
}

.StartContainer {
    background-color: #d7d0c8;
    padding-left: 2px;
    width: 75px;
    height: 29px;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
    border-top: white 2px solid;
    border-left: white 2px solid;
}

.StartIcon {
    padding-top: 1px;
    width: 23px;
    height: 23px;
}

@media (max-width: 1280px) {
    .Toolbar {
        display: none !important;
    }
}