/* Main Page Container */
.Main {
    font-family: w95faregular, Arial, sans-serif;

    font-weight: bold;
    font-style: normal;
    background-color: #386fa7;
    /*min-height: 100vh;*/
    min-height: auto;
}

.MainArea {
    height: 100vh;
}

/* Task Bar */
.Footer {
    width: 100%;
    background-color: #d7d0c8;
    height: 2em;
}

/* Local Time Component */
.ToolBarTime {
    margin-right: 0.25em;
}

/* Start Button */
.StartBtn {
    width: 5%;
    height: 95%;
    margin-top: 2px;
    text-align: right;
    background-color: #d7d0c8;
    border-top: #808080 1px solid;
    border-left: #808080 1px solid;
    border-right: #ffffff 1px solid;
    border-bottom: #ffffff 1px solid;
}

#clippy {
    display: none;
}

@media (max-width: 1280px) {
    #clippy {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    #Toolbar {
        display: none;
    }
}