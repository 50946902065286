

/* Main CSS for Desktop Icons. */
.Icon {
    width: 80px;
    margin: 10px;
    color: white;
    letter-spacing: 1px;
    text-align: center;
}

.DesktopIconButton {
    width: 50px;
    height:  50px;
}

.DesktopIconButtonSelected {
    width: 50px;
    height:  50px;
}

.DesktopIconButtonLabelSelected {
    color: black;
    background-color: #0000a8;
}

.DesktopIconImage {
    width:  50px;
    height:  50px;
}
