
/* Main Window Style */
.Window {
    position: absolute;
    top: 5%;
    left: 3%;
    width: 94%;
    height: 87%;
    background-color: #d7d0c8;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
    border-top: white 2px solid;
    border-left: white 2px solid;
    overflow-y: auto;
    overflow-x: hidden;
}

.WindowHeaderBarStack {
    background-color: #000080;
    margin-top:  2px;
    margin-right: 2px;
    margin-left: 2px;
    height: 32px;
}

.WindowContentContainer {
    height: 50em;
}

/* Buttons */
.CloseButton {
    position: absolute;
    top: 6px;
    right: 5px;
    width: 25px;
    height: 25px;
    background-color: #d7d0c8;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
    border-top: white 2px solid;
    border-left: white 2px solid;
    font-weight: bold;
}

.MinimizeButton {
    position: absolute;
    top: 6px;
    right: 35px;
    width: 25px;
    height: 25px;
    background-color: #d7d0c8;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
    border-top: white 2px solid;
    border-left: white 2px solid;
    font-weight: bold;
}

.WindowTitle {
    letter-spacing: 1px;
    font-size: 19px;
    padding-left: 8px;
    padding-top: 2px;
    color: white;
    vertical-align: center;
}

/* About Me Content */

.AboutMeContent {
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
}

.AboutMeHeaderContainer {
    background-color: white;
    width: auto;
}

.AboutMeContentArea {
    margin-left: 2px;
    height: inherit;
}

#SabishiiOS {
    width: 75%;
    position: relative;
    left: 55px;
}

#SystemInfoPanel {
    background-color: #ffffff;
    height: 37em;
}

/* Contact Me Content */
#ContactBottomBar {
    position: relative;
    bottom: 0;
    left: 2px;
    background-color: #d4d0c7;
    width: 100% ;
    height: 100px;
    border-top: #ffffff 1px solid;
    box-shadow: #808080 0 -1px 0;
}

.ContactContentArea {
    margin-left: 2px;
}

#ContactRightPane {
    background-color: white;
    padding-left: 10px;
}

#ContactHeader {
    text-align: left;
}

#ContactLinks {
    margin-top: 25px;
    height: 200px;
    width: 50%;
}

#ContactLeftImage {
    height: 791px;
    width: 100%;
}

#ContactInformationPane {
    text-align: right;
    background-color: white;
}

#ContactRightPaneHeader {
    float: right;
    width: 447px;
    height: 200px;
}

#ContactDetailsPaneText {
    margin-right: 30px;
}

/* Other */
.HorizonalLine {
    width: 90%;
    margin-top: 20px;
    margin-left: 20px;
    border-top: #808080 2px solid;
    border-bottom: #ffffff 1px solid;
    border-right: #ffffff 1px solid;
}

#Toast {
    background-color: #d7d0c8;
    border-bottom: black 2px solid;
    border-right: black 2px solid;
    border-top: white 2px solid;
    border-left: white 2px solid;
}