


/* Time */
.ToolBarTime {
    width: 100%;
    height: 95%;
    margin-right: 1em;
    padding-right: 1em;
    text-align: right;
    background-color: #d7d0c8;
    border-top: #808080 1px solid;
    border-left: #808080 1px solid;
    border-right: #ffffff 1px solid;
    border-bottom: #ffffff 1px solid;
}

.TimeText {
    margin-top: 2px;
}

