body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  min-width: 1280px;
  min-height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'w95faregular';
  src: url("./fonts/w95fa-webfont.woff2") format('woff2'),
  url('./fonts/w95fa-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@media (max-width: 1000px) {
  body {
    min-width: auto;
  }
}
