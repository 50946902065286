

/* Main CSS for Window Icons. */
.WindowIconButton {
    width: 100px;
    height:  100px;
}

.WindowIconImage {
    width:  100px;
    height:  100px;
}

.WindowIconButtonLabel {
    margin-left: 18px;
    color: black;
}

.WindowIconButtonSelected {
    width: 100px;
    height:  100px;
}

.WindowIconButtonLabelSelected {
    background-color: #0000a8;
}